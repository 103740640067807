<template>
    <div style="height: 100%; width: 100%;">
        <router-view :class="classValue()" :key="$route.name"></router-view>
    </div>
</template>

<script>
    import Horizontal from '@/components/Welfare/Index/Horizontal/Index.vue'
    import Vertical from '@/components/Welfare/Index/Vertical/Index.vue'
    

    export default {
        components:{
            Horizontal,
            Vertical
        },
        data() {
            return {
               
            }
        },
        methods:{
            classValue() {
                let classVal = []
                if(this.$store.getters.currentPageVersion === 'Horizontal') {
                    classVal.push('h-child-box')
                }else {
                    classVal.push('v-child-box')
                }
                if(this.$route.name === "WelfareListIndex") {
                    classVal.push('index-active')
                }
                return classVal
            }
        }
    }
</script>

<style scoped>
    .v-child-box,
    .h-child-box {
        height: 100%;
        padding: 12px;
    }
    .index-active {
        padding: 0;
    }
</style>